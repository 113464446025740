<template>
  <TsRadio
    v-model="selectedBranchWithStock"
    append-class="w-full bg-natural-soft-white hover:bg-blue-200 transition-colors p-4 items-start cursor-pointer"
    outlined
    label-class="p-0 pl-2 cursor-pointer"
  >
    <template #label>
      <TsMedia append-class="w-full flex justify-between items-center">
        <TsMediaStart>
          <div class="flex flex-col gap-y-2">
            <TsTypography
              size="sm"
              append-class="text-idle-black font-semibold"
            >
              {{ branchName }}
            </TsTypography>
            <TsTypography size="xs" append-class="flex items-center space-x-1">
              <TsIcon name="mdi:clock-outline" size="16" />
              <span> Open at 7am </span>
            </TsTypography>
            <TsTypography size="xs" append-class="flex items-center space-x-1">
              <TsIcon name="fluent:location-28-regular" size="16" />
              <span> 8.2 miles </span>
            </TsTypography>
          </div>
        </TsMediaStart>
        <TsMediaEnd>
          <div class="flex flex-col items-end gap-y-3 w-full">
            <TsQuantityCounter
              :data-testid="`entered-quantity-for-${branchName}`"
              v-model="quantity"
              :maximum-allowable-quantity="availableStock"
              :disable-decrement="
                selectedBranchWithStock?.branch.name !== branchName
              "
              :disable-increment="
                selectedBranchWithStock?.branch.name !== branchName
              "
              :disabled="selectedBranchWithStock?.branch.name !== branchName"
            />

            <TsTag
              rounded
              append-class="px-3 py-1 block text-center w-max"
              variant="success"
              :label="`${availableStock} in stock`"
              size="xs"
            />
          </div>
        </TsMediaEnd>
      </TsMedia>
    </template>
  </TsRadio>
</template>

<script lang="ts" setup>
import type { BranchWithStock } from "~/types/ecom/product/stock.type";

type Props = {
  branchName: string;
  availableStock: number;
};

const props = withDefaults(defineProps<Props>(), {});

const quantity = defineModel<number>("quantity", {
  required: true,
});

const selectedBranchWithStock = defineModel<BranchWithStock | null>({
  default: null,
});
</script>
